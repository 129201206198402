

















import { BrowserStorageService, ServiceManager, ServiceType } from 'client-website-ts-library/services';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class WelcomeModal extends Vue {
  private active = false

  @Prop()
  private readonly title!: string;

  @Prop({ default: 'Close' })
  private readonly buttonText!: string;

  mounted() {
    const bss = ServiceManager.Require(ServiceType.BrowserStorage) as BrowserStorageService;

    this.active = bss.getItem('hasShownWelcomeModal') !== 'true';

    if (!this.active) bss.setItem('hasShownWelcomeModal', 'true');
  }

  handleClick(): void {
    this.active = false;
    this.$emit('got_active', this.active);
  }

  @Watch('active')
  handleBodyOverflow(isActive: boolean) {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  beforeDestroy() {
    document.body.style.overflow = '';
  }
}
