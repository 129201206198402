

















































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Profile } from 'client-website-ts-library/types';
import Cycler from './Cycler/Cycler.vue';
import CyclerItem from './Cycler/CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
  },
})
export default class TechnologyCycler extends Vue {
  @Prop()
  private agentProfiles: Profile[] | null = null;

  private breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
  ];
}
